<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)"
          ref="form_add_or_update"
          class="repeater-form" @submit="processResults">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Class') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('class')"
                  rules="required"
              >
                <select v-model="classes_id" class="form-control"
                        name="classes_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(cls, key) in classes" :key="key" :value="cls.id">{{cls.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Version') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('version')"
                  rules="required"
              >
                <select v-model="version_id" class="form-control"
                        name="version_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(version, key) in versions" :key="key" :value="version.id">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Shift') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('shift')"
                  rules="required"
              >
                <select v-model="shift_id" class="form-control"
                        name="shift_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(shift, key) in shifts" :key="key" :value="shift.id">{{shift.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Wing') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('wing')"
                  rules="required"
              >
                <select v-model="wing_id" class="form-control"
                        name="wing_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(wing, key) in wings" :key="key" :value="wing.id">{{wing.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Student group') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('student group')"
                  rules="required"
              >
                <select v-model="student_group_id" class="form-control"
                        name="student_group_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(grp, key) in student_groups" :key="key" :value="grp.id">{{grp.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Academic Year') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('academic year')"
                  rules="required"
              >
                <select v-model="academic_year_id" class="form-control"
                        name="academic_year_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(acYear, key) in academic_years" :key="key" :value="acYear.id">{{acYear.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Exam type') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('exam type')"
                  rules="required"
              >
                <select v-model="exam_type_id" class="form-control"
                        name="exam_type_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(eType, key) in active_exam_types" :key="key" :value="eType.id">{{eType.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" class="mt-2 pt-1">
            <b-button variant="outline-primary" type="submit">
              {{ $t('Generate') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BFormGroup,
  BForm,BRow, BCol,BCardText
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment"
export default {
  name:'ResultGenerate',
  components: {
    BCardCode,
    BFormGroup,
    BButton,BCardText,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      classes_id:null,
      // version_id:null,
      // shift_id:null,
      wing_id:null,
      student_group_id:null,
      academic_year_id:null,
      exam_type_id:null,
      classes:[],
      versions:[],
      shifts:[],
      wings:[],
      student_groups:[],
      required,
      dir: false,
      model_mode:'add',
      selected_row:{},
    }
  },
  methods:{
    processResults(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.form_add_or_update);
          apiCall.post('/result/processing', data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
            } else this.$toaster.error(response.data.message);
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      });
    },

    /*method for get all classes*/
    async getClasses(){
      await apiCall.get('/get/active/class/with/v/s/sg/wings').then((response)=>{
        this.classes=response.data;
      }).catch(()=>{
        this.classes=[];
      })
    },
    jsonDecode(data){
      try{
        return JSON.parse(data);
      }
      catch (e){
        return [];
      }
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['academic_years','active_exam_types']),
    active_exam_types(){
      return this.$store.getters.active_exam_types;
    },
  },
  created() {
    this.getClasses();
    if(this.active_exam_types.length <1){
      this.$store.dispatch('GET_ACTIVE_EXAM_TYPE');
    }
    if(this.academic_years.length <1){
      this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    }
  },
  watch: {
    classes_id(){
      if(this.classes_id){
        // this.versions=[];
        // this.shifts=[];
        this.wings=[];
        this.student_groups=[];
        // this.version_id=null;
        // this.shift_id=null;
        this.wing_id=null;
        this.student_group_id=null;
        let cls = this.classes.find(item=>item.id===this.classes_id);
        if(cls){
          // this.versions=cls.versions;
          // this.shifts=cls.shifts;
          this.wings=cls.wings;
          this.student_groups=cls.student_groups;
        }
      }else {
        // this.versions=[];
        // this.version_id=null;
        // this.shifts=[];
        // this.shift_id=null;
        this.wings=[];
        this.wing_id=null;
        this.student_group_id=null;
        this.student_groups=[];
      }
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>