var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"form_add_or_update",staticClass:"repeater-form",attrs:{"method":"post","action":"javascript:void(0)"},on:{"submit":_vm.processResults}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Class'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('class'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.classes_id),expression:"classes_id"}],staticClass:"form-control",attrs:{"name":"classes_id","state":errors.length > 0 ? false:null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.classes_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.classes),function(cls,key){return _c('option',{key:key,domProps:{"value":cls.id}},[_vm._v(_vm._s(cls.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Wing'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('wing'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.wing_id),expression:"wing_id"}],staticClass:"form-control",attrs:{"name":"wing_id","state":errors.length > 0 ? false:null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.wing_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.wings),function(wing,key){return _c('option',{key:key,domProps:{"value":wing.id}},[_vm._v(_vm._s(wing.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Student group'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('student group'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.student_group_id),expression:"student_group_id"}],staticClass:"form-control",attrs:{"name":"student_group_id","state":errors.length > 0 ? false:null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.student_group_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.student_groups),function(grp,key){return _c('option',{key:key,domProps:{"value":grp.id}},[_vm._v(_vm._s(grp.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Academic Year'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('academic year'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.academic_year_id),expression:"academic_year_id"}],staticClass:"form-control",attrs:{"name":"academic_year_id","state":errors.length > 0 ? false:null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.academic_year_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.academic_years),function(acYear,key){return _c('option',{key:key,domProps:{"value":acYear.id}},[_vm._v(_vm._s(acYear.year))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t('Exam type'))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('exam type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.exam_type_id),expression:"exam_type_id"}],staticClass:"form-control",attrs:{"name":"exam_type_id","state":errors.length > 0 ? false:null},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.exam_type_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Select one")]),_vm._l((_vm.active_exam_types),function(eType,key){return _c('option',{key:key,domProps:{"value":eType.id}},[_vm._v(_vm._s(eType.name))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2 pt-1",attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"outline-primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Generate'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }